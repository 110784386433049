import React,{useState,useEffect} from 'react';
import { Routes } from '../../utils/route';
import {Equipment} from '../../components/driverForm/equipmentCheckinFormDriver'
import {
  Form,
  Spin,
  Select,
  Row,
  Col,
  InputNumber,
  Radio,
  message,
  Input,
  Upload,
  Button,
  AutoComplete
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { assendingSort,IndustryType,TextCapitalize,TextUpperCase } from '../../utils/helper';
import { connect } from 'react-redux';
import DriverLayout from '../../components/driverLayout';
import { fetchYardList } from '../../actions/yardLocationAction';
import { CustomerList } from '../../actions/customerAction';
import { usePosition } from '../../utils/usePosition';
import API from '../../api';
import {SDS} from '../../components/EquipmentCheck/FoodCheckin'
import { FoodTruck,FoodConfirmation } from '../../components/EquipmentCheck/comp/equipmentCheckinForm';
const { Option } = Select;

const EqDropOff=(props)=>{
  const token =
  typeof window !== 'undefined' ? localStorage.getItem('token') : '';
const { GATSBY_BACKEND_URL } = process.env;
const file = {
  name: 'file',
  lable: 'SDS',
  action: `${GATSBY_BACKEND_URL}image/upload`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  type: 'upload',
  col: 12,
  rules: [
    {
      // required: true,
      message: 'Please upload sds!',
    },
  ],
};
const { latitude, longitude } = usePosition();
const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [equipment, setEquipment] = useState(IndustryType()!=="Food_Grade"? Equipment():FoodTruck());
    const { customerList, yardLocationData } = props;
    const [eqCategory,setEqCategory]=useState(null)
    const [eqTypeData,setEqTypeData]=useState(null);
    const [eqType,setEqType]=useState(null);
    const [eqNumber,setEqNumber]=useState(null)
    const [assetId,setAssetId]=useState('')
    const [confirm,setConfirm]=useState(IndustryType()!=="Food_Grade"?[{name:'sds',  col: 8}]:FoodConfirmation())
    const {
      imageUpdate,
      imageDelete,
      assetSearch,
      equipmentCheckin,
      washTypeRequest
    } = API;
    useEffect(()=>{
    
    },[])
    useEffect(() => {
        const callback = (val) => {
          if (val === true) {
            setLoading(false);
            const cb=()=>{}
            props.fetchYardList();
            props.CustomerList(cb);
            EquipmentCategory()
            if(IndustryType()==="Food_Grade"){
            washTypeRequest().then((res)=>{
              const _data=res.data.data;
              const washReq = []
              _data &&
              _data.map((data) => {
                washReq.push({
                    value: data.id.toString(),
                    displayValue: data['service_name.param_description']+" "+(data.category?`(${TextCapitalize(data.category)})`:""),
                    category:data.category
                  });
                });
            eqDropdownUpdate('wash_type_requested',assendingSort(washReq));
          })
        }
          }
        }
        Routes('welcome', callback)
      }, [])
      const onSearch = (text, value) => {
        eqDropdownUpdate('equipment_no', eqNumber);
        const _value = eqNumber && eqNumber.filter((item) => item.value === text);
        if (_value.length === 0) {
          setAssetId('');
          disableItems('equipment_type', false, false, true);
          // form.setFieldsValue({ equipment_type: '' });
        } else {
          disableItems('equipment_type', true, false, true);
          disableItems('equipment_no', false, true, true);
          onSerchEquipmentType(_value[0].eqNo)
          form.setFieldsValue({ equipment_type: _value[0].eqNo });
          setAssetId(_value[0].asset_id);
        }
      };
    

      const onSelect = (data, value) => {
        value &&
          value.map((item) => {
            if (item.value === data) {
              setAssetId(item.asset_id);
              onSerchEquipmentType(item.eqNo)
              form.setFieldsValue({ equipment_type: item.eqNo });
              disableItems('equipment_type', true, false, true);
              // disableItems('equipment_no', false, true, true);
            }
          });
      };

      const onSerchEquipmentType=(eqNo)=>{
          eqTypeData.map((type)=>{
            if(type.value===eqNo){
              form.setFieldsValue({ equipment_category: type.parent_id });    
              dropDownEqCategory(type.parent_id,'new')    
            }
          })
      }
      const EquipmentCategory=()=>{
        const tabParam = JSON.parse(localStorage.getItem('data'));
        const _eqCategory = [];
        const _eqType=[]
        tabParam.TabParams &&
          tabParam.TabParams.map((data) => {
            if (data.param_key === 'equipment_category') {
              _eqCategory.push({
                value: data.param_value.toString(),
                displayValue: data.param_name,
              });
            }else if(data.param_key==="equipment_type"){
              _eqType.push({
                value: data.param_value.toString(),
                displayValue: data.param_name,
                parent_id:data.parent_id.toString()
              })
            }
          });
          setEqTypeData(assendingSort(_eqType))
          setEqCategory(assendingSort(_eqCategory))
      }

      const dropDownEqCategory=(e,type)=>{
        if(type==='update'){
          form.setFieldsValue({
            equipment_type:''
          });
        }
        if(e==='1080'){
          disableItems('quantity', false, false, true);
          disableItems('equipment_no', false, true, false);
        }else{
          disableItems('quantity', true, false, true);
          disableItems('equipment_no', false, true, true);
        }
        
        const tabParam = JSON.parse(localStorage.getItem('data'));
        const _eqType = [];
        tabParam.TabParams &&
          tabParam.TabParams.map((data) => {
            if (data.param_key === 'equipment_type'&&e===data.parent_id.toString()) {
              _eqType.push({
                value: data.param_value.toString(),
                displayValue: data.param_name,
                parent_id:data.parent_id.toString()
              });
            }
          });
          setEqType(assendingSort(_eqType))
      }
      const dropDownEqType=(e)=>{
        const eqNo=form.getFieldValue('equipment_no')
        const filterEqNo= eqNumber.filter((item)=>eqNo===item.value)
        if(filterEqNo.length!==0){
         form.setFieldsValue({
           equipment_no: '',
         });
        }
          eqTypeData.map((item)=>{
              if(item.value===e){
          item.eqNo&& item.eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
          eqDropdownUpdate('equipment_no', item.eqNo);
        }
          })
      }
      const disableItems = (name, value, require, requireVal) => {
        const eq = [...equipment];
        eq.map((item) => {
          if (item.name === name) {
            item.disabled = value;
            if (require) {
              item.rules.map((rules) => {
                rules.required = requireVal;
              });
            }
          }
        });
        setEquipment(eq);
      };
      const dropDownSelect=(e, value,dropValue)=>{
        if (value === 'client_cust_id') {
          form.setFieldsValue({
            equipment_no: '',
            location_id: '',
            last_known:IndustryType()!=="Food_Grade"?'nonhaz':''
          });
          if(IndustryType()!=="Food_Grade"){
          disableItems('quantity', true, false, true);
          }
          assetSearch(e).then((res) => {
            const data = res.data.data;
            const eqNo = [];
            data &&
              data.map((_data) => {
                eqNo.push({
                  value: _data.equipment_no.toString(),
                  eqNo: `${_data['equipment_type_details.param_value']}` ,
                  asset_id: _data.asset_id,
                });
              });
              eqNo&&eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
              setEqNumber(eqNo)
          
         const _eqType=  [...eqTypeData]
         _eqType.map((_item)=>{
             _item.eqNo=[]
            const arr=[]
           eqNo.map((eq)=>{
             if(eq.eqNo===_item.value){
               arr.push(eq)
             }
             _item.eqNo=arr
           })
           }) 
           setEqTypeData(eqTypeData)
              eqDropdownUpdate('equipment_no', eqNo);
          });


        }
        else if(value === 'wash_type_requested') {
          const filter =dropValue.filter((item)=>item.value===e)
          form.setFieldsValue({kosher_certified:filter[0]?.category==="kosher certified"?true:false})
        }
      } 
      useEffect(
        () => {
          const yardArr = [];
          yardLocationData &&
            yardLocationData.map((data) => {
              yardArr.push({
                value: data.id.toString(),
                displayValue: TextCapitalize(data.name),
                coordinates: data.coordinates,
              });
            });
          eqDropdownUpdate('location_id',assendingSort(yardArr));
        },
        [yardLocationData]
      );
      useEffect(
        () => {
          const customer = customerList;
          const clientCustomer = [];
          customer &&
            customer.map((data) => {
              clientCustomer.push({
                value: data.clients_cust_id.toString(),
                displayValue: data.name,
              });
            });
          eqDropdownUpdate('client_cust_id',assendingSort(clientCustomer));
        },
        [props.customerList]
      );
      const eqDropdownUpdate = (name, value) => {
        const eq = [...equipment];     
        eq.map((item) => {
          if (item.name === name) {
            item.value = value;
          }
        });
        setEquipment(eq);
      };
      const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };
      const uploadImg = (info) => {
        if (info.file.status === 'uploading') {
          setBtnDisable(true)
        }
        if (info.file.status === 'done') {
          if (props.asset_txns_id) {
            const local = JSON.parse(localStorage.getItem('data'));
            let doc_type = '';
            local.TabParams.map((item) => {
              if (item.param_description === 'Safety Sheets (SDS)') {
                doc_type = item.param_value;
              }
            });
    
            const fileData = {
              doc_path: info.file.response.data.filepath,
              coordinates: [latitude??	0.00, longitude??	0.00],
              asset_txns_id: props.asset_txns_id,
              doc_type: doc_type,
              doc_name: 'Safety Sheets (SDS)',
            };
    
            imageUpdate(fileData).then((res) => {
              const _data = res.data.data;
              const imageArr = [...fileList];
              if (_data) {
                imageArr.push({
                  doc_id: _data.doc_id,
                  uid: _data.doc_id,
                  name: _data.doc_name,
                  status: 'done',
                  url: _data.doc_path,
                  thumbUrl: _data.doc_path,
                });
              }
              setFileList(imageArr);
            });
          } 
          setBtnDisable(false)
          // message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          setBtnDisable(false);
          // message.error(`${info.file.name} file upload failed.`);
        } else if (info.file.status === 'removed') {
          if (props.asset_txns_id) {
            const imageArr = [...fileList];
            let url = {};
            let uid = '';
            imageArr.map((item, i) => {
              if (!info.file.response) {
                if (item.url === info.file.url) {
                  uid = item.doc_id;
                  url = { url: item.url };
                  imageArr.splice(i, 1);
                }
              } else if (info.file.response.data.filepath === item.url) {
                uid = item.doc_id;
                url = { url: item.url };
                imageArr.splice(i, 1);
              }
            });
            setFileList(imageArr);
    
            imageDelete(uid, url).then((res) => {});
          }
        }
      };
      const disableInput = () => {
        const customerId = form.getFieldValue('client_cust_id');
      if (!customerId) {
          return true;
        } else {
          return false;
        }
      };

      const onFinish = (values) => {
        setBtnDisable(true);
        let coordinates = [];
        const location = form.getFieldValue('location_id');
        if(IndustryType()!=="Food_Grade"){
        const last_known = form.getFieldValue('last_known');
        if (last_known === 'haz') {
          values = { ...values, last_known: 'haz-' + values.hazardous };
        } else {
          values = { ...values, last_known: 'nonhaz-' + values.hazardous };
        }
      }
        let yardDisplay;
        equipment.map((item) => {
          if (item.name === 'location_id') {
            item.value.map((cord) => {
              if (cord.value === location) {
                yardDisplay = cord.displayValue;
                coordinates = cord.coordinates;
              }
            });
          }
        });
        
    
        values = {
          ...values,
          location_id: parseInt(values.location_id),
          equipment_type: parseInt(values.equipment_type),
          client_cust_id: parseInt(values.client_cust_id),
        };
        if(IndustryType()=="Food_Grade"){
          delete values.equipment_type
        }
        if (assetId) {
          values = { ...values, asset_id: assetId };
        }
          const fileArr = [];
          values.file &&
            values.file.map((res) => {
              fileArr.push(res.response.data.filepath);
            });
            delete values.file;
          equipmentCheckin({ ...values, coordinates: coordinates, sds: fileArr })
            .then(async (res) => {
              if (res.data.success === true) {
                form.resetFields();
                setBtnDisable(false);
                message.success('Succesfully Added & Checked In Asset');
              } else {
                setBtnDisable(false);
                message.error(res.data.message);
              }
            })
            .catch((err) => {
              message.error(err.message);
              setBtnDisable(false);
            });
      };
    

      if(!loading){
        return (
            <DriverLayout >
                <Form form={form}
                id="checkin"
                onFinish={onFinish}>
              
                <Row gutter={24}>
                {equipment &&
            equipment.map((_form,i) => _form.name!=="po_id"&&(
              _form.name==="custom1"?<Col span={12}>
              <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                        //  getValueFromEvent={_form.type === 'upload' ? normFile : ''}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="equipment_category"
                      rules={[
                            {
                              required: true,
                              message: 'Please Select equipment category!',
                              whitespace: true,
                            },
                          ]}
                      label={<span>EQUIPMENT TYPE</span>}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                          disabled={disableInput()}
                        virtual={false}
                          allowClear={false}
                          placeholder={`CATEGORY`}
                          onChange={(e) => dropDownEqCategory(e,'update')}
                        >
                         {eqCategory&&eqCategory.map((item)=>(
                          <Option value={item.value}>
                                {item.displayValue}
                              </Option>
                         ))
                              
                            }
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={12}>
      
    
                                  <Form.Item
                                   requiredMark="optional"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="equipment_type"
                      rules={ [
                            {
                              required: true,
                              message: 'Please Select equipment type!',
                              whitespace: true,
                            },
                          ]}
                      label={<span></span>}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                          disabled={disableInput()}
                        virtual={false}
                          allowClear={false}
                          placeholder={`SUB CATEGORY`}
                          onChange={(e) => dropDownEqType(e)}
                        >
                         <Option value="" disabled>
                            SUB CATEGORY
                          </Option>
                             {eqType&&eqType.map((item)=> <Option value={item.value}>
                                {item.displayValue}
                              </Option>)}
                            
                        </Select>
                    </Form.Item>
                    </Col>
                    </Row>
                    </Col>
              
             : _form.name==="custom2"?
             <Col span={24}>
             <Row gutter={24}>
               <Col span={12}>
             <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="remark"
                      rules={[]}
                      label={<span>DETAILS FOR BULK ITEMS/ REMARKS</span>}
                    >
                      <Input.TextArea
                        disabled={disableInput()}
                        style={{ height: 135 }}
                      />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Row gutter={12}>

              <Col span={24}>
             <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="owner"
                      rules={[]}
                      label={<span>OWNER</span>}
                    >
                      <Input
                          disabled={disableInput()}
                      placeholder={_form.placeholder}
                    />
                    </Form.Item>
                    </Col>

                    <Col span={24}>
             <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="manifest"
                      rules={[]}
                      label={<span>MANIFEST#</span>}
                    >
                      <Input
                          disabled={disableInput()}
                      placeholder={_form.placeholder}
                    />
                    </Form.Item>
                    </Col>


                    </Row>
                    </Col>
                  

                    </Row>
             </Col>
             
             :
              <Col span={_form.col}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={_form.name}
                  rules={_form.rules}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                >
{ _form.type === 'dropdown' ? (
                    <Select
                    disabled={
                      (_form.name !== 'client_cust_id' && disableInput()) ||
                      _form.disabled
                    }
                    virtual={false}
                      allowClear={false}
                      placeholder={`SELECT ${_form.lable}`}
                      style={_form.style}
                      onChange={(e) => dropDownSelect(e, _form.name,_form.value)}
                    >
                      {_form.value &&
                        _form.value.map((item) => (
                          <Option value={item.value}>
                            {item.displayValue}
                          </Option>
                        ))}
                    </Select>)
                    : _form.type === 'autocomplete' ? (
                      <AutoComplete
                      disabled={disableInput()}
                        options={_form.value}
                        onSelect={(e) => onSelect(e, _form.value)}
                        onSearch={(e) => onSearch(e, _form.value)}
                        placeholder=""
                      />
                    ) 
                    : _form.type === 'radio' ? (
                      <Radio.Group
                      disabled={_form.disabled||disableInput()}
                        // onChange={(e) => handleSource(e.target.value)}
                      >
                        {_form.value.map((item) => (
                          <Radio value={item.value}>{item.displayValue}</Radio>
                        ))}
                      </Radio.Group>
                    )
                    :  _form.type === 'textArea' ? (
                      <Input.TextArea
                        disabled={disableInput()}
                        style={{ height: 87 }}
                      />
                    ) :
                    _form.type==="number"?
                    <InputNumber
                    disabled={_form.disabled || disableInput()} 
                  min={0}
                    style={{width:'100%'}}
                  precision={2}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />:
                    
                    (
                      <Input
                      disabled={_form.disabled ||disableInput()}
                      placeholder={_form.placeholder}
                    />
                    )            
                  }
                </Form.Item>

              
              </Col>
            ))}
             {IndustryType()==="Food_Grade"?
            <Col span={24} style={{background:'#e0e1e5'}}>
             <Col span={24} style={{marginBottom:35,marginTop:35}}>  
                 <span style={{fontSize:'1.4rem', fontWeight:500}}>PROVIDE DROP OFF CONFIRMATION DETAILS:
                   </span>
                 </Col>
          {
      confirm.map((_form) => (
              _form.name==="sds"?<SDS file={file} disableInput={disableInput()} uploadImg={uploadImg} normFile={normFile} fileList={fileList}/>:
              <Col span={_form.col}>
                <Form.Item
                  labelCol={{span: 24}}
                  wrapperCol={{span: 24}}
                  name={_form.name}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  rules={_form.rules}
                >
                 <Input
                  disabled={_form.disabled || disableInput()}
                  placeholder={_form.placeholder}
                />
                 
                </Form.Item>
              </Col>
            ))
          }
            </Col>:<SDS file={file} disableInput={disableInput()} uploadImg={uploadImg} normFile={normFile} fileList={fileList}/>}

                </Row>
                </Form> 
                <div style={{display:'flex',justifyContent:'center',marginTop:25}}>
                <Button size="large" onClick={()=>form.resetFields()} style={{background:'#ff6b72',color:'white',marginRight:10}}>Reset</Button>
                <Form.Item> <Button  disabled={btnDisable || disableInput()}  form="checkin" htmlType="submit" size="large" style={{background:'#3e82f7',color:'white',marginLeft:10}}>Submit</Button> </Form.Item>
                </div>
            </DriverLayout>
    )
}
else{
  return(<div className="spinner-main"><Spin/></div>)
}
}


const mapStateToProps = (state) => ({
  yardLocationData: state.yardLocationData.yardLocationData,
  customerList: state.customer.customerList,
});

export default connect(mapStateToProps, {
  fetchYardList,
  CustomerList,
})(EqDropOff);